import React, { useMemo } from "react"
import "./ExamResult.less"
import { devLog, getImageUrl, translatePath } from "../utils"
import ContainerWithPadding from "./ContainerWithPadding"
import { useTranslation } from "react-i18next"
import { Button, Card, Col, Row } from "antd"
import { Link, navigate } from "gatsby"
import Countdown from "./Countdown"
import moment from "moment"
import {
  DEFAULT_LANDSCAPE_IMAGE_SIZE,
  DEFAULT_WALLPAPER_IMAGE_SIZE,
} from "../constants/imageSizes"

const ExamResult = ({ data }) => {
  const { t } = useTranslation()
  devLog({ examResult: data })
  const { title, wallpaper, landscape_success, landscape_locked, id, seo } =
    data.exam.certification

  const { certificationId } = data
  const background = getImageUrl(wallpaper, DEFAULT_WALLPAPER_IMAGE_SIZE)
  const successCover = getImageUrl(
    landscape_success,
    DEFAULT_LANDSCAPE_IMAGE_SIZE
  )
  const failCover = getImageUrl(landscape_locked, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  const content = useMemo(() => {
    const {
      passed,
      wrongAnswers,
      exam,
      certificationId,
      totalUserCorrectAnswers,
      totalCorrectAnswers,
    } = data

    devLog({
      passed,
      wrongAnswers,
      exam,
      certificationId,
      totalUserCorrectAnswers,
      totalCorrectAnswers,
    })

    if (passed) {
      return (
        <div>
          <h1 className="result-title success">{t("label:examCongrats")}</h1>
          <p className="message success">{t("message:examPassed")}</p>
          <div className="certification-preview-wrapper">
            <Card
              style={{ width: "100%", marginBottom: 50 }}
              hoverable
              cover={
                <img alt={title} src={passed ? successCover : failCover} />
              }
            />
          </div>
          <Row gutter={[32, 16]} className="exam-actions">
            <Col xs={24} sm={24} md={12}>
              <h2>{t("message:requireCertificationShipping")}</h2>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <h2>{t("message:viewYourCertificationInYourLibrary")}</h2>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Button className="purple">
                <Link
                  to={`/online-tests/require-shipping?id=${certificationId}`}
                >
                  {t("button:getItNow")}
                </Link>
              </Button>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Button className="purple">
                <Link to={`${translatePath("/online-tests/all")}`}>
                  {t("button:gotoCertifications")}
                </Link>
              </Button>
            </Col>
          </Row>
        </div>
      )
    } else {
      const duration = moment.duration(exam.duration).humanize() //(exam.duration / 1000 / 60).toFixed(0)
      const coolDown = moment.duration(exam.coolDown).humanize() //(exam.coolDown / 1000 / 60 / 60).toFixed(0)
      const count = exam.sampleCount

      return (
        <div>
          <h1 className="result-title fail">{t("label:examFailed")}</h1>
          <p className="message fail">
            {t("message:examNotPassed", {
              errorCount: totalCorrectAnswers - totalUserCorrectAnswers,
              correctCount: totalUserCorrectAnswers,
            })}
          </p>
          <p>{t("message:examMessage2", { duration, coolDown, count })}</p>
          <h4>{t("message:tryAgainExamAfter")}:</h4>
          <Countdown
            duration={exam.coolDown}
            size={106}
            onExpire={() => {
              navigate(`${translatePath("/online-tests")}/${seo.slug}/exam`)
            }}
          />
          <br />
          <Button className="purple" style={{ marginTop: 60 }}>
            <Link to={`/online-tests/review-exam?id=${certificationId}`}>
              {t("button:reviewExam")}
            </Link>
          </Button>
          <br />
          <Button className="purple" style={{ marginTop: 60 }}>
            <Link to={`${translatePath("/online-tests/all")}`}>
              {t("button:gotoCertifications")}
            </Link>
          </Button>
        </div>
      )
    }
  }, [data])

  return (
    <div
      className="exam-result-wrapper"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 100%), url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ContainerWithPadding size="small" align="center">
        {content}
      </ContainerWithPadding>
    </div>
  )
}

export default ExamResult
